<template>
  <Header />
  <Herosection />
  <HowToPrayTheRosary />
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Herosection from "@/components/HeroSectionPage.vue";
import Footer from "@/components/Footer.vue";
import HowToPrayTheRosary from "@/components/prayers/HowToPrayTheRosary.vue";

export default {
  components: {
    Header,
    Footer,
    Herosection,
    HowToPrayTheRosary,
  },
};
</script>
