<template>
  <section id="about" class="about">
    <div class="container">
      <div class="row">
        <div class="col-md-12 aboutsection">
          <a href="../../prayers" class="cta-link">Back to prayers</a>
          <h2 class="pt-4" style="color: #0b416c">How To Pray The Rosary</h2>
          <hr />
          <div class="section-title mt-2">
            <p>
              How to pray the Rosary: On the 5 decades of a rosary, we pray and
              meditate on the corresponding Mystery from the
              <a href="../prayers/mysteries-of-the-rosary/#joyfulmysteries"
                >Joyful Mysteries</a
              >, the
              <a href="../prayers/mysteries-of-the-rosary/#sorrowfulmysteries"
                >Sorrowful Mysteries</a
              >, the
              <a href="../prayers/mysteries-of-the-rosary/#gloriousmysteries"
                >Glorious Mysteries</a
              >, or the
              <a href="../prayers/mysteries-of-the-rosary/#lightmysteries"
                >Light Mysteries</a
              >.
            </p>
            <ul>
              <li>
                While holding the crucifix make the
                <a href="../prayers/rosary-prayers/#sign_of_the_cross"
                  >Sign of the Cross</a
                >
                and then recite the
                <a href="../prayers/rosary-prayers/#apostles_creed"
                  >Apostles Creed</a
                >.
              </li>
              <li>
                Recite the
                <a href="../prayers/rosary-prayers/#our_father">Our Father</a>
                on the first large bead.
              </li>
              <li>
                Say a
                <a href="../prayers/rosary-prayers/#hail_mary">Hail Mary</a>
                for an increase of faith, hope and charity on each of the three
                small beads.
              </li>
              <li>
                Recite the
                <a href="../prayers/rosary-prayers/#glory_be"
                  >Glory Be to the Father</a
                >
                on the next large bead.
              </li>
              <li>
                Say the
                <a href="../prayers/rosary-prayers/#fatima_prayer"
                  >Fatima Prayer</a
                >
                “O my Jesus” on the large bead.
              </li>
              <li>
                Recall the first Rosary Mystery and recite the Our Father on the
                large bead.
              </li>
              <li>
                On each of the adjacent ten small beads (also refered to as a
                decade) recite a Hail Mary while reflecting on the mystery.
              </li>
              <li>
                On the next large bead, recite the Glory Be to the Father, the
                Fatima prayer.
              </li>
              <li>
                Each succeeding decade is prayed in a similar manner by
                recalling the appropriate mystery, reciting the Our Father, ten
                Hail Marys while reflecting on the mystery, the Glory Be to the
                Father, and the Fatima prayer.
              </li>
              <li>
                When the fifth mystery is completed, the Rosary is Customarily
                concluded with the
                <a href="../prayers/rosary-prayers/#hail_holy_queen"
                  >Hail Holy Queen</a
                >, and the Sign of the Cross.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.section-title a {
  color: #0022bb;
}
.cta-link {
  border-radius: 30px;
  padding: 5px 10px;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  border: 2px solid #0880e8;
  color: #0880e8;
}
.cta-link:hover {
  color: #fff;
  background-color: #0880e8;
}
.socialfooter {
  padding: 0px 0px 10px 0px !important;
}
.social-links a {
  margin-right: 20px;
}
.social-links {
  display: flex;
}
.saintheading {
  margin: 0 auto;
  padding: 50px;
}
.about {
  padding: 30px 30px 30px 30px;
  background: #f9f9f9;
  position: relative;
}
.about:before {
  content: "";
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.about .container {
  position: relative;
}

.about .content h2 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 26px;
}

@media (max-width: 992px) {
  .about {
    padding: 30px;
  }
}
</style>
